import { Avatar, Box, Button, createStyles, Divider, Flex, Grid, Group, Input, Modal, Space, Text } from "@mantine/core";
import { IconMail, IconMailForward, IconMapPin, IconPhoneCall, IconSearch, IconStar, IconStarHalf, IconWorld } from "@tabler/icons";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { RequestApi } from "../../apis";
import { IAnchor } from "../../interfaces/IAnchor";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IPermissions } from "../../interfaces/IPermissions";
import { IReference } from "../../models/General";
import { dateSecFormat } from "../../utils/date";
import { ColumnType, ITableRef, Table } from "../table";
import { RequestForm } from "./form";

export function RequestList() {
  const ref = useRef<ITableRef>(null);
  const { classes, theme } = useStyle();
  const [action, setAction] = React.useState<any[]>([]);
  const [query, setQuery] = React.useState<string>("");
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { partnerLegalEntityTypes } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    user,
    partnerLegalEntityTypes,
    onClick: (key, record) => {
      switch (key) {
        case "allow": {
          setAction(["invite", record]);
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
  };

  const reload = () => {
    ref.current?.reload();
  };

  const collapseChildren = (record: any) => {
    return (
      <div className={classes.card} key={record.id}>
        <Grid>
          <Grid.Col span={3}>
            <Flex gap={20}>
              <Avatar src={"avatar"} size={50} radius="md">
                <Avatar src={record?.partner?.logo} alt="" />
              </Avatar>
              <Flex gap={4} direction="column">
                <Text size="md" weight={500} className={classes.name}>
                  {record?.partner?.businessName || "-"}
                </Text>
                <Text size="xs" color="dimmed">
                  {record?.partner?.businessNameEng || "-"}
                </Text>
                <Text size="xs" color="dimmed">
                  <Flex align={"center"}>
                    <IconStar stroke={1.5} size={16} className={classes.icon} color={theme.colors.yellow[6]} />
                    <IconStar stroke={1.5} size={16} className={classes.icon} color={theme.colors.yellow[6]} />
                    <IconStar stroke={1.5} size={16} className={classes.icon} color={theme.colors.yellow[6]} />
                    <IconStarHalf stroke={1.5} size={16} className={classes.icon} color={theme.colors.yellow[6]} />
                    (1223)
                  </Flex>
                </Text>
              </Flex>
            </Flex>
          </Grid.Col>
          <Grid.Col span={4}>
            {record?.partner?.web ? (
              <Group noWrap spacing={10} mt={5}>
                <IconWorld stroke={1.5} size={16} className={classes.icon} />
                <Text size="xs" color="dimmed">
                  {record?.partner?.web || "-"}
                </Text>
              </Group>
            ) : (
              <Text size="xs" color="dimmed">
                {"-"}
              </Text>
            )}
            {record?.partner?.email ? (
              <Group noWrap spacing={10} mt={3}>
                <IconMail stroke={1.5} size={16} className={classes.icon} />
                <Text size="xs" color="dimmed">
                  {record?.partner?.email}
                </Text>
              </Group>
            ) : (
              <Text size="xs" color="dimmed">
                {"-"}
              </Text>
            )}
            {record?.partner?.phone ? (
              <Group noWrap spacing={10} mt={3}>
                <IconPhoneCall stroke={1.5} size={16} className={classes.icon} />
                <Text size="xs" color="dimmed">
                  {record?.partner?.phone}
                </Text>
              </Group>
            ) : (
              <Text size="xs" color="dimmed">
                {"-"}
              </Text>
            )}
            {record?.partner?.businessAddress ? (
              <Group noWrap spacing={10} mt={3}>
                <IconMapPin stroke={1.5} size={16} className={classes.icon} />
                <Text size="xs" color="dimmed">
                  {record?.partner?.businessAddress}
                </Text>
              </Group>
            ) : (
              <Text size="xs" color="dimmed">
                {"-"}
              </Text>
            )}
          </Grid.Col>
          <Grid.Col span={5}></Grid.Col>
        </Grid>
      </div>
    );
  };

  return (
    <>
      <Flex direction={"column"} gap="md" sx={{ minHeight: "60vh", height: "100%" }}>
        <Grid mt="sm">
          <Grid.Col span={3}>
            <Input
              iconWidth={130}
              onChange={(e) => setQuery(e.currentTarget.value)}
              placeholder="хайлт хийх"
              rightSection={<IconSearch size={18} style={{ display: "block", opacity: 0.5 }} />}
              icon={
                <Group w={"calc(100% - 20px)"} align="center" position="apart" noWrap>
                  <Text size="sm" weight={450} c="#44566C">
                    {"Партнер нэр"}
                  </Text>
                  <Text>:</Text>
                </Group>
              }
              key={1}
            />
          </Grid.Col>
        </Grid>
        <Divider />
        <Table
          ref={ref}
          collapse
          collapseChildren={collapseChildren}
          name="invite.list"
          columns={columns}
          filters={{ query: query }}
          loadData={(data) => RequestApi.invite(data!)}
        />
      </Flex>
      <Modal withCloseButton={false} opened={action[0] === "invite"} onClose={() => setAction([])} size={"1200px"} centered>
        <RequestForm action={action} onCancel={onCancel} reloadTab={reload} />
      </Modal>
    </>
  );
}

const useHeader = ({
  user,
  onClick,
  partnerLegalEntityTypes,
}: {
  user: any;
  onClick: (key: string, record: IAnchor) => void;
  partnerLegalEntityTypes: any;
}): ColumnType<IAnchor>[] => [
  {
    title: "#",
    render: (_r, index) => (
      <Text size="sm" weight={500} c="#44566C">
        {index + 1}
      </Text>
    ),
    width: "30px",
  },
  {
    title: "Үйлдэл",
    render: (record) => {
      const isFinanceRoleManagementAllowed = (user.permissions || []).some(
        (c: IPermissions) => c.module === "CLIENT" && c.code === "CLIENT_FINANCE_ROLE_MANAGEMENT" && c.isReview,
      );

      return (
        <Button
          onClick={(key) => onClick(isFinanceRoleManagementAllowed ? "allow" : "", record)}
          size="xs"
          variant="subtle"
          disabled={!isFinanceRoleManagementAllowed}>
          <IconMailForward size={18} stroke={2} />
          <Space w={"xs"} />
          Зөвшөөрөх
        </Button>
      );
    },
  },
  {
    title: "ТТД",
    sorter: true,
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C">
          {record?.partner?.regNumber || "-"}
        </Text>
      );
    },
  },
  {
    title: "Партнер нэр",
    sorter: true,
    dataIndex: "partner",
    render: (record) => {
      return (
        <Box>
          <Text size="sm" weight={500} c="#44566C" w="max-content">
            {record?.partner?.businessName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#144e9d" w="max-content">
            {record?.partner?.refCode || "-"}
          </Text>
        </Box>
      );
    },
  },
  {
    title: "ААН хэлбэр",
    sorter: true,
    dataIndex: "legalEntityType",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {(partnerLegalEntityTypes || []).find((c: IReference) => c.code === record?.partner?.legalEntityType)?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Худалдан авагч бизнес",
    sorter: true,
    dataIndex: "BUYER",
    render: (record) => {
      return (
        <Box>
          {(record?.businesses || [])
            .filter((c: any) => c.type === "BUYER")
            ?.map((t: any) => {
              return (
                <div key={t.id}>
                  <Text size="sm" weight={500} c="#44566C" w="max-content">
                    {t?.profileName || "-"}
                  </Text>
                  <Text size="sm" weight={500} c="#144e9d" w="max-content">
                    {t?.refCode || "-"}
                  </Text>
                </div>
              );
            })}
        </Box>
      );
    },
  },
  {
    title: "Нийлүүлэгч бизнес",
    sorter: true,
    dataIndex: "SUPPLIER",
    render: (record) => {
      return (
        <Box>
          {(record?.businesses || [])
            .filter((c: any) => c?.type === "SUPPLIER")
            ?.map((t: any) => {
              return (
                <div key={t.id}>
                  <Text size="sm" weight={500} c="#44566C" w="max-content">
                    {t?.profileName || "-"}
                  </Text>
                  <Text size="sm" weight={500} c="#144e9d" w="max-content">
                    {t?.refCode || "-"}
                  </Text>
                </div>
              );
            })}
        </Box>
      );
    },
  },
  {
    title: "Хүсэлт гаргасан",
    sorter: true,
    dataIndex: "requestedDate",
    render: (record) => {
      return (
        <Text size="sm" weight={500} c="#44566C" w="max-content">
          {dateSecFormat(record.requestedDate)}
        </Text>
      );
    },
  },
  {
    title: "Тохиргоо хийсэн",
    sorter: true,
    dataIndex: "user",
    render: (record) => {
      return (
        <Box>
          <Text size="sm" weight={500} c="#44566C" w="max-content">
            {record?.user?.lastName[0] || "-"}.{record?.user?.firstName || "-"}
          </Text>
          <Text size="sm" weight={500} c="#144e9d" w="max-content">
            {record?.user?.email || "-"}
          </Text>
        </Box>
      );
    },
  },
];

const useStyle = createStyles((theme) => ({
  content: {
    display: "flex",
    gap: "8px",
  },
  icon: {
    color: theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.gray[5],
  },

  name: {},

  card: {
    width: "100%",
  },
}));
