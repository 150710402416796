import { ActionIcon, Button, CloseButton, Divider, Flex, Grid, Group, Input, Paper, Space } from "@mantine/core";
import { IconBuildingCommunity, IconSearch, IconX } from "@tabler/icons";
import React from "react";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { TextField } from "../form/text-field";
import { FormLayout } from "../layout";
import { SupplierLedProgramAnchorList } from "./anchor-list";

export function SupplierLedAnchorForm({
  action,
  setAction,
  setFieldValue,
  values,
}: {
  action: any;
  setAction: (e: any) => void;
  setFieldValue: (e: string, e2: any) => void;
  values: any;
}) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [query, setQuery] = React.useState<string>("");
  const [filters, setFilters] = React.useState<any>({
    query: "",
    type: "SUPPLIER_LED",
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      setFieldValue("businessId", values.businessId);
      setFieldValue("anchor", { ...values.anchor, role: values.anchor.type });
      setFieldValue("bankAccounts", values.bankAccounts);
      setFieldValue("checkedAnchor", values.checkedAnchor);
      setFieldValue("finUsers", values.finUsers);
      setFieldValue("hasAnchor", true);
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }

    setAction([]);
    setLoading(false);
  };

  React.useEffect(() => {
    let timeout;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(async () => {
      setFilters({ ...filters, query: query });
    }, 800);
  }, [query, filters]);

  return (
    <>
      <FormLayout title="Хөтөлбөрийн Анкор" my={0} extra={[<CloseButton key="cancel" onClick={() => setAction([])} />]}>
        <Paper>
          <Divider />
          <Grid>
            <Grid.Col span={12}>
              <Input.Wrapper label="Анкор бизнэс сонгох:" required>
                <Paper>
                  <Flex gap={8}>
                    <Input
                      key={1}
                      value={query}
                      w={"50%"}
                      onChange={(e) => setQuery(e.currentTarget.value)}
                      placeholder="Хайлт хийх"
                      icon={<IconSearch size={18} />}
                      rightSection={
                        <ActionIcon
                          onClick={() => {
                            setQuery("");
                          }}>
                          <IconX size={14} />
                        </ActionIcon>
                      }
                    />
                    <TextField
                      rightSection={
                        <ActionIcon
                          onClick={() => {
                            setFieldValue("businessId", undefined);
                            setFieldValue("anchor", undefined);
                            setFieldValue("bankAccounts", undefined);
                            setFieldValue("checkedAnchor", undefined);
                            setFieldValue("finUsers", undefined);
                            setFieldValue("hasAnchor", false);
                          }}>
                          <IconX size={14} />
                        </ActionIcon>
                      }
                      name="checkedAnchor"
                      icon={<IconBuildingCommunity size={18} />}
                      readOnly
                      w={"50%"}
                      placeholder="Анкор Бизнес нэр, код"
                    />
                  </Flex>
                  <Space h={"lg"} />
                  <SupplierLedProgramAnchorList values={values} setFieldValue={setFieldValue} filters={{ ...filters, type: "SUPPLIER_LED" }} />
                </Paper>
              </Input.Wrapper>
            </Grid.Col>
          </Grid>
          <Divider />
          <Group position="right" mt="md">
            <Button onClick={() => onSubmit(values)} loading={loading}>
              Нэмэх
            </Button>
          </Group>
        </Paper>
      </FormLayout>
    </>
  );
}
