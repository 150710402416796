import React from "react";
import { useNavigate, useParams } from "react-router";
import { PageLayout } from "../../../components/layout";
import { Button, Modal, Paper, Space, Tabs, Text } from "@mantine/core";
import { IconPackage, IconPlus, IconShoppingCart } from "@tabler/icons";
import { FundingProgramBuyerLedList } from "../funding-program-buyer/list";
import { FundingProgramSupplierLedList } from "../funding-program-supplier/list";
import { FundingProgramRegisterForm } from "./register-form";
import { useSelector } from "react-redux";
import { IAuth } from "../../../interfaces/IAuth";

export function FundingProgram() {
  const params = useParams();
  const navigate = useNavigate();
  const [action, setAction] = React.useState<string[]>([]);
  const [tabKey, setTabKey] = React.useState<string | null>(params.type || "BUYER");
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const titles = (val: string) => {
    switch (val) {
      case "SUPPLIER": {
        return "Supplier-LED хөтөлбөр";
      }

      case "BUYER": {
        return "Buyer-LED хөтөлбөр";
      }
    }
  };

  const onChange = (value: string) => {
    navigate(`/cf/funding-program/${value}`);
    setTabKey(value);
  };

  const breadcrumbs = useBreadcrumb(titles(tabKey as string));

  const onCancel = async () => {
    setAction([]);
  };

  return (
    <>
      <PageLayout
        title={tabKey === "BUYER" ? "Buyer-LED хөтөлбөрийн жагсаалт" : "Supplier-LED хөтөлбөрийн жагсаалт"}
        subTitle={
          tabKey === "BUYER"
            ? "Уламжлалт зээлийн бүтээгдэхүүн суурьтай санхүүжилтийн хөтөлбөр"
            : "Уламжлалт зээлийн бүтээгдэхүүн суурьтай санхүүжилтийн хөтөлбөр"
        }
        breadcrumb={breadcrumbs}
        extra={[
          <div key={1}>
            {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PROGRAM_MANAGEMENT_SL" && c.isCreate).length > 0 ||
              (user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PROGRAM_MANAGEMENT_BL" && c.isCreate).length > 0}
            <Button size="sm" key={0} onClick={() => setAction(["new"])} leftIcon={<IconPlus />} variant="outline">
              Шинэ хөтөлбөр
            </Button>
          </div>,
        ]}>
        <Paper radius="sm" p="md" withBorder>
          <Tabs value={tabKey} onTabChange={(e) => onChange(e as string)}>
            <Tabs.List>
              {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PROGRAM_MANAGEMENT_BL" && c.isView).length > 0 && (
                <Tabs.Tab value="BUYER" icon={<IconShoppingCart color={tabKey === "BUYER" ? "#317169" : "#44566C"} />}>
                  <Text size="sm" weight={500} c="#44566C">
                    Buyer-LED
                  </Text>
                </Tabs.Tab>
              )}

              {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PROGRAM_MANAGEMENT_SL" && c.isView).length > 0 && (
                <Tabs.Tab value="SUPPLIER" icon={<IconPackage color={tabKey === "SUPPLIER" ? "#317169" : "#44566C"} />}>
                  <Text size="sm" weight={500} c="#44566C">
                    Supplier-LED
                  </Text>
                </Tabs.Tab>
              )}
            </Tabs.List>
            <Space h={10} />
            {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PROGRAM_MANAGEMENT_BL" && c.isView).length > 0 && (
              <Tabs.Panel value="BUYER">{<FundingProgramBuyerLedList />}</Tabs.Panel>
            )}

            {(user?.permissions || []).filter((c) => c.module === "LBF" && c.code === "LBF_PROGRAM_MANAGEMENT_SL" && c.isView).length > 0 && (
              <Tabs.Panel value="SUPPLIER">{<FundingProgramSupplierLedList />}</Tabs.Panel>
            )}
          </Tabs>
        </Paper>
      </PageLayout>
      <Modal opened={action && action[0] === "new"} onClose={() => setAction([])} withCloseButton={false} size="lg" centered>
        <FundingProgramRegisterForm onCancel={onCancel} />
      </Modal>
    </>
  );
}

const useBreadcrumb = (type: any) => [
  {
    to: "/",
    label: "Үндсэн",
  },
  { label: "Зээл суурьтай санхүүжилт" },
  {
    label: type,
  },
];
